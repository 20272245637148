<template>
    <ef-dialog
        title="查询管理的门店"
        :visible.sync="dialogFormVisible"
        width="80%"
        top="60px"
        @opened="handleQuery"
        @confirm="confirmSelection"
    >
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-select
                                v-model.number="form.type"
                                :disabled="meta.fixType"
                                @change="searchDeptGroup"
                                clearable
                            >
                                <el-option value="" label="请选择" />
                                <el-option label="总部" :value="1" />
                                <el-option label="配送中心" :value="2" />
                                <el-option label="门店" :value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select v-model="form.deptGroupCode" filterable clearable>
                                <el-option value="" label="请选择" />
                                <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.deptGroups" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="省份">
                            <el-select v-model="form.provinceCode" clearable>
                                <el-option value="" label="请选择" />
                                <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.provinces" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input v-model="form.search" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :offset="2">
                        <el-button type="primary" @click="searchHandleQuery" size="small">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                ref="pickUpManagedDeptTable"
                stripe
                :data="tableData"
                size="mini"
                max-height="500"
                highlight-current-row
                @select="handleSelected"
                @select-all="handleSelectAll"
                :header-cell-class-name="headerRowClassName"
            >
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column type="selection" />
                <el-table-column label="部门名称" width="200" prop="name" />
                <el-table-column label="类型" width="120" prop="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | deptType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="省份" width="120" prop="provinceName" />
                <el-table-column label="地市" width="80" prop="cityName" />
                <el-table-column label="区县" width="80" prop="districtName" />
                <el-table-column label="地址" min-width="240" prop="address" />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </ef-dialog>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import Provinces from 'js/Provinces';
import EfDialog from 'components/EfDialog';

export default {
    name: 'PickUpManagedDept',
    components: { EfDialog },
    props: {
        multi: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            form: {
                search: '',
                type: '',
                provinceCode: '',
                deptGroupCode: '',
                ignoreDeptCodes: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],

            dialogFormVisible: false,
            meta: {
                fixType: false,
                deptGroups: [],
                provinces: Provinces,
            },
        };
    },
    created() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.deptGroups = rst || [];
        });
    },
    methods: {
        headerRowClassName(row) {
            if (!this.multi && row.rowIndex === 0 && row.column.type === 'selection') {
                //单选操作类型，且单元格位于第一行、且column类型为selection，则隐藏全选按钮
                return 'hiddenSelectAllCheckbox';
            }
        },
        handleSelected(selection, row) {
            if (!this.multi) {
                //单选操作类型，清空已勾选数据，并设置当前row勾选
                this.$refs.pickUpManagedDeptTable.clearSelection();
                this.$refs.pickUpManagedDeptTable.toggleRowSelection(row, true);
            }
        },
        handleSelectAll() {
            if (!this.multi) {
                //单选操作类型，清空所有勾选，不进行任何勾选数据
                this.$refs.pickUpManagedDeptTable.clearSelection();
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            Util.queryTable(this, '/system/dept/queryExtend', { params: this.form });
        },
        confirmSelection() {
            if ((this.$refs.pickUpManagedDeptTable.selection || []).length > 0) {
                this.$emit('ok', this.$refs.pickUpManagedDeptTable.selection);
            }
            this.dialogFormVisible = false;
        },
        show(option) {
            const _option = {
                ignores: [], //忽略的部门,
                deptType: null, //默认的机构类型
                fixType: false, //固定机构
                noGroup: false, //忽略机构组查询
            };
            Object.assign(_option, option);
            if (_option.ignores.length) {
                this.form.ignoreDeptCodes = _option.ignores.join(',');
            }
            this.form.type = _option.deptType;
            if (!Util.isEmpty(_option.deptType)) {
                UrlUtils.DeptGroup(this, (rst) => {
                    this.meta.deptGroups = rst.filter((r) => r.deptType == _option.deptType);
                });
            }
            if (_option.noGroup) {
                this.form.deptGroupCode = '';
            }
            this.meta.fixType = _option.fixType;

            this.dialogFormVisible = true;
        },
        // 选择了机构类型时 查询对应的机构组
        searchDeptGroup() {
            // 先清空选中的机构组
            this.form.deptGroupCode = '';
            const deptType = this.form.type;
            if (!Util.isEmpty(this.form.type)) {
                UrlUtils.DeptGroup(this, (rst) => {
                    this.meta.deptGroups = rst.filter((r) => r.deptType == deptType);
                });
            } else {
                this.meta.deptGroups = [];
            }
        },
    },
    filters: {
        deptType(deptType) {
            if (typeof deptType == 'undefined') {
                return '';
            }
            if (deptType == '1') {
                return '总部电商';
            }
            if (deptType == '2') {
                return '配送中心';
            }
            if (deptType == '3') {
                return '门店';
            }
        },
    },
};
</script>

<style scoped>
/**
单选类型时，隐藏全选按钮
 */
/deep/ .hiddenSelectAllCheckbox .el-checkbox__inner {
    display: none;
}
</style>
