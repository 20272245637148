<template>
    <div class="PickUpAdvs">
        <ef-dialog
            title="查询广告基本信息"
            :visible.sync="dialogFormVisible"
            width="80%"
            top="60px"
            @opened="handleQuery"
            @confirm="confirmSelection"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="客户名称">
                                <el-select v-model="form.customerCode" filterable>
                                    <el-option label="请选择" value="" />
                                    <el-option
                                        :label="c.name"
                                        :value="c.code"
                                        :key="c.code"
                                        v-for="c in meta.customers"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="广告名称">
                                <el-input v-model="form.search" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="广告类型">
                                <el-select v-model="form.type">
                                    <el-option label="请选择" value="" />
                                    <el-option label="图片" value="0" />
                                    <el-option label="视频" value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-button @click="searchHandleQuery" size="medium" type="primary">查询</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    ref="pickupAdvTable"
                    stripe
                    :data="tableData"
                    size="mini"
                    highlight-current-row
                    max-height="450"
                    @select="handleSelected"
                    @select-all="handleSelectAll"
                    :header-cell-class-name="headerRowClassName"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" />
                    <el-table-column type="selection" />
                    <el-table-column prop="name" label="基本信息" width="180">
                        <template slot-scope="scope">
                            <span>广告名称:</span><span>{{ scope.row.name }}<br /></span> <span>广告客户:</span
                            ><span>{{ scope.row.customerName }}<br /></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预览" width="180">
                        <template slot-scope="scope">
                            <el-image
                                v-if="isImgAdvertFileType(scope.row.type)"
                                style="width: 100px; height: 100px"
                                :src="scope.row.uploadAccessory"
                                :preview-src-list="[scope.row.uploadAccessory]"
                                class="img-preview"
                            />
                            <video
                                v-else-if="isVideoAdvertFileType(scope.row.type)"
                                style="width: 100px; height: 100px"
                                :src="scope.row.uploadAccessory"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="其他信息" width="260">
                        <template slot-scope="scope">
                            <span>广告类型:</span>
                            <span>
                                {{ isImgAdvertFileType(scope.row.type) ? '图片' : '视频' }}({{
                                    scope.row.timeLength
                                }}秒)<br />
                            </span>
                            <span>创建人 :</span><span>{{ scope.row.creator }}<br /></span> <span>创建日期:</span
                            ><span>{{ scope.row.createTime }}<br /></span> <span>广告描述:</span
                            ><span>{{ scope.row.content }}<br /></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                :disabled="!scope.row.uploadAccessory"
                                @click="handlePreview(scope.row.type, scope.row.uploadAccessory)"
                                >预览</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    :current-page="form.page"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
        </ef-dialog>
        <!--预览广告组件-->
        <advert-preview ref="advertPreview" />
    </div>
</template>

<script>
import Util from '../../js/Util';
import UrlUtils from '../../js/UrlUtils';
import EfDialog from 'components/EfDialog';
import AdvertUtils from 'js/AdvertUtils';
import AdvertPreview from 'views/menu/advert/manage/AdvertPreview';

export default {
    name: 'PickUpAdvs',
    components: { EfDialog, AdvertPreview },
    props: {
        multi: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            form: {
                status: 1,
                customerCode: '',
                type: '',
                name: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            dialogFormVisible: false,
            meta: {
                customers: [],
            },
        };
    },
    mounted() {
        UrlUtils.Customer(this, (d) => {
            this.meta.customers = d;
        });
    },
    methods: {
        headerRowClassName(row) {
            if (!this.multi && row.rowIndex === 0 && row.column.type === 'selection') {
                //单选操作类型，且单元格位于第一行、且column类型为selection，则隐藏全选按钮
                return 'hiddenSelectAllCheckbox';
            }
        },
        handleSelected(selection, row) {
            if (!this.multi) {
                //单选操作类型，清空已勾选数据，并设置当前row勾选
                this.$refs.pickupAdvTable.clearSelection();
                this.$refs.pickupAdvTable.toggleRowSelection(row, true);
            }
        },
        handleSelectAll() {
            if (!this.multi) {
                //单选操作类型，清空所有勾选，不进行任何勾选数据
                this.$refs.pickupAdvTable.clearSelection();
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            Util.queryTable(this, '/advert/manage/page', { params: this.form });
        },
        confirmSelection() {
            if ((this.$refs.pickupAdvTable.selection || []).length > 0) {
                this.$emit('ok', this.$refs.pickupAdvTable.selection);
            }
            this.dialogFormVisible = false;
        },
        show() {
            this.dialogFormVisible = true;
        },
        isImgAdvertFileType(advertFileType) {
            return AdvertUtils.isImgAdvertFileType(advertFileType);
        },
        isVideoAdvertFileType(advertFileType) {
            return AdvertUtils.isVideoAdvertFileType(advertFileType);
        },
        handlePreview(advertFileType, advertUrl) {
            this.$refs.advertPreview.showDialog(advertFileType, advertUrl);
        },
    },
};
</script>

<style scoped>
/**
单选类型时，隐藏全选按钮
 */
/deep/ .hiddenSelectAllCheckbox .el-checkbox__inner {
    display: none;
}
/deep/ .img-preview .el-icon-circle-close {
    color: red;
}
</style>
